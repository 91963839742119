/**
 * @file
 * Main styles. Here must be you stylesheet.
 * Use it well.
 */

@include border-box-sizing();

html {
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#app {
  box-shadow: 0 0 8px 1px #f1f1f1;

  .modal-open & {
    transition: all 1500ms;
    -webkit-filter: blur(7px);
    filter: blur(7px);
  }

  @include breakpoint($xs-media) {
    margin-top: 60px;
  }
}

#modalBackdrop {
  background: rgba($ui-site-color-2, .5) !important;
}

#modalContent {
  background: white;
  border-radius: 0;
  overflow: hidden;
  max-width: rem(600);
  width: 100%;
  position: fixed !important;
  top: 50% !important;
  transform: translateY(-50%);
  filter: blur(0);
  box-shadow: 0 15px 30px rgba(white, .45);

  .ctools-modal-content {
    margin: 0;
    border: none;
    width: 100%;

    .modal-header {
      background: $ui-site-color-2;
      color: white;
      padding: 10px;

      .modal-title {
        color: inherit;
        font-weight: bold;
        @include font-size(13.5, true);
      }

      .close {
        color: white;
      }
    }
  }

  #modal-content {
    padding: 10px;

    input {
      display: inline-block;
    }

    .field-suffix {
      color: #9b9b9b;
    }
  }
}

#header {
  .top {
    padding: rem(20);
  }

  @include breakpoint($xs-media) {
    display: none;
  }
}

#logo-and-slogan {
  .slogan {
    text-align: center;
    @include font-size(20, true);
    color: $ui-site-color-2;
    margin-bottom: rem(15);
  }

  @include breakpoint($xs-media) {
    .slogan {
      @include font-size(18, true);
    }
  }
}

#block-block-1 {
  .telephone {
    @include font-size(20, true);
    text-align: center;
    color: $ui-site-color-2;
    font-weight: bold;

    &.first {
      @include font-size(24, true);
    }
  }

  .call-towtruck {
    @include button-variant(white, $ui-site-color-1, transparent);
    font-weight: bold;
    text-align: center;
    display: table;
    margin: rem(10) auto 0;
    @include font-size(16, true);
    padding: rem(6) rem(44);
  }

  @include breakpoint($xs-media) {
    @include susy-clearfix;
    .telephone {
      width: 50%;
      float: left;
      @include font-size(15, true);
      margin: rem(10) 0;
      text-align: center;

      &.first {
        @include font-size(15, true);
      }

      > a {
        border-bottom: 1px dashed $ui-site-color-2;
      }
    }
  }

  @include breakpoint($md-media) {
    .telephone {
      @include font-size(20, true);

      &.first {
        @include font-size(30, true);
      }
    }

    .call-towtruck {
      @include font-size(20, true);
    }
  }
}

#block-block-2 {
  .hours {
    text-align: center;
    color: $ui-site-color-1;
    font-weight: bold;
    @include font-size(20, true);
    text-transform: uppercase;
    margin-bottom: rem(10);
  }

  .image {
    height: rem(75);
    display: table;
    margin: 0 auto;
  }

  .image-and-text {
    .image {
      float: right;
    }

    .text {
      color: $ui-site-color-2;
      @include font-size(18);
      line-height: 75px;
      font-weight: bold;
      padding-left: rem(15);

      @include breakpoint($xs-media) {
        line-height: 1.4;
        text-align: center;
        padding: 0;
      }

      @include breakpoint($sm-media) {
        @include font-size(14);
      }

      @include breakpoint($md-media) {
        @include font-size(18);
      }
    }
  }

  @include breakpoint($xs-media) {
    margin-bottom: 0;

    .hours {
      @include font-size(16, true);
      margin: 0;
    }
    .image {
      display: none;
    }
  }

  @include breakpoint($md-media) {
    .hours {
      @include font-size(26, true);
    }
  }
}

#navigation {
  .slicknav_menu {
    background: $ui-site-color-2;
    color: white;
    padding: 0 rem(10);

    .slicknav_btn {
      background: none;
      display: block;
      padding: rem(6);

      .slicknav_menutxt {
        color: white;
        @include font-size(20, true);
        font-family: $font-family;
        @include icon('bars');
      }
    }

    .slicknav_nav {
      margin: 0;
      padding: 0;
      list-style: none;

      li {
        a {
          color: white;
          padding: 5px 10px;
          margin: 2px 5px;
          @include font-size(15, true);
          display: block;
        }
      }
    }
  }

  @include breakpoint($xs-media) {
    #block-superfish-1 {
      display: none;
    }

    .mobile {
      display: block;

      #admin-menu-shortcuts-responsive {
        display: none;
      }
    }
  }

  @include breakpoint($sm-media) {
    #block-superfish-1 {
      display: block;
    }

    .mobile {
      display: none;
    }
  }
}

#superfish-1 {
  background: $ui-site-color-2;
  padding: 0 rem(20);

  > li {
    border-right: 1px solid rgba(white, .15);
    border-left: 1px solid rgba(black, .15);

    &:last-child {
      border-right: none;
    }

    &:first-child {
      border-left: none;
    }

    > a,
    > span {
      cursor: pointer;
      text-align: center;

      &:hover {
        background: rgba(white, .1);
      }
    }
  }

  li {
    position: relative;

    > ul {
      top: rem(45);
      background: $ui-site-color-2;
      border: 1px solid rgba(black, .1);

      li {
        border-bottom: 1px solid rgba(black, .1);

        &:last-child {
          border-bottom: none;
        }

        a {
          color: white;
          padding: rem(6) rem(10);
          font-weight: normal;
          text-transform: capitalize;
        }
      }
    }

    a,
    span {
      display: block;
      color: white;
      font-weight: bold;
      text-transform: uppercase;
      @include font-size(18, true);
      padding: rem(10) rem(6);

      &.menuparent {
        &:before {
          display: block;
          content: '';
        }
        /*&:after {
          font-family: FontAwesome;
          content: "\f078";
          @include font-size(14, true);
          margin-left: rem($icon-padding);
          color: $ui-site-color-1;
          top: -2px;
          position: relative;
        }*/
      }
    }
  }
}

#block-superfish-1 {
  margin: 0;
}

#main {
  @include susy-clearfix;
  padding: rem(20);

  .front & {
    padding: 0;
  }
}

#page-title {
  margin-top: 0;
}

#footer {
  background: url('../../images/blue-bg.png') repeat;

  .telephone {
    text-align: center;
    background: url('../../images/striped-bg.png') repeat;
    padding: rem(10);
    @include font-size(30, true);
    text-transform: uppercase;
    font-weight: bold;

    .first {
      color: $ui-site-color-1;
    }

    .second {
      color: $ui-site-color-2;
    }

    .phone {
      color: $ui-site-color-2;
      font-weight: 300;
    }
  }

  > .content {
    padding: rem(20);
    color: white;

    .block {
      margin-bottom: 0;

      .title {
        text-transform: uppercase;
        @include font-size(18, true);
        margin-top: 0;
        font-weight: bold;
      }
    }
  }

  .copyright {
    background: darken($ui-site-color-2, 5%);
    padding: rem(10) rem(20);
    color: white;
  }

  @include breakpoint($xs-media) {
    .telephone {
      @include font-size(20, true);

      .phone {
        border-bottom: 1px dashed $ui-site-color-2;
      }
    }

    > .content {
      .block {
        margin-bottom: rem(20);
      }
    }
  }
}

#block-block-3 {
  .text {
    text-align: center;
    font-weight: bold;
    margin-top: rem(10);
  }
}

#block-block-4 {
  .item {
    margin-bottom: rem(20);
    padding-left: rem(50);
    position: relative;

    &:before {
      @extend .fa;
      width: rem(50);
      text-align: center;
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      color: white;
      @include font-size(28, true);
    }
  }

  .address {
    &:before {
      @extend .fa-map-marker;
    }
  }

  .telephones {
    &:before {
      @extend .fa-phone;
    }
  }

  .email {
    &:before {
      @extend .fa-envelope-o;
    }
  }

  @include breakpoint($sm-media) {
    padding-left: rem(65);
  }
}

#contact-entityform-edit-form {
  .pre-instructions {
    .first {
      .phone, .email, .time, .address, .skype, .social {
        line-height: rem(25);
        &:before {
          @extend .fa;
          margin-right: rem(6px);
          width: 20px;
          text-align: center;
        }
      }
      //.phone:before {
      //  @extend .fa-phone;
      //}
      //.email:before {
      //  @extend .fa-envelope-o;
      //}
      //.time:before {
      //  @extend .fa-clock-o;
      //}
      //.address:before {
      //  @extend .fa-globe;
      //}
      //.skype:before {
      //  @extend .fa-skype;
      //}
      //.social:before {
      //  @extend .fa-share-square-o;
      //}
      .photo {
        width: rem(360);
        float: right;
        //margin-right: rem($icon-padding *2);
        border-radius: rem(3);
      }
    }
    .second {
      .scheme {
        .title {
          &:before {
            @extend .fa;
            @extend .fa-road;
            margin-right: rem(6px);
            width: 20px;
            text-align: center;
          }
        }
        > ymaps {
          width: 100% !important;
        }
      }
      .route {
        .title {
          &:before {
            @extend .fa;
            @extend .fa-map-marker;
            margin-right: rem(6px);
            width: 20px;
            text-align: center;
          }
        }
      }
    }
    .third {
      .ppl {
        .info, .chief {
          &:before {
            @extend .fa;
            margin-right: rem(6px);
            width: 20px;
            text-align: center;
          }
        }
        .chief:before {
          @extend .fa-user;
        }
        .info:before {
          @extend .fa-file-text-o;
        }
        .about {
          @include font-size(12px);
        }
        .photo {
          @include breakpoint($sm-media) {
            margin-top: -31px;
            width: 100px;
          }
        }
      }
      .form-pane {
        .title {
          &:before {
            @extend .fa;
            @extend .fa-envelope-o;
            margin-right: rem($icon-padding);
          }
        }
      }
    }
  }
  @include breakpoint($xs-media) {
    .pre-instructions {
      .first {
        .photo {
          float: none;
          width: 100%;
          margin-bottom: rem(15);
        }
      }
    }
  }
}

.article-teaser {
  border-bottom: 1px solid $gray-lighter;
  margin-bottom: rem(15);
  padding-bottom: rem(15);

  .promo {
    transform: scale(1);
    transition: all 500ms;
    &:hover {
      transition: all 500ms;
      transform: scale(1.05);
    }
  }

  .title {
    margin: rem(10) 0;
    @include font-size(17, false);
    line-height: 23px;

    a {
      border-bottom: 1px solid rgba($ui-site-color-2, .5);
    }
  }

  .content {
    line-height: 17px;
  }
}

.paragraph-text-filtered-full {
  margin-bottom: rem(15);
}

.paragraph-gallery-full {
  .gallery {
    .item {
      margin-bottom: rem(15);
    }
  }
}

.testimonial-teaser {
  background: #f9f9f9;
  border: 1px solid #e6e6e6;
  box-shadow: inset 0 1px 0 0 white;
  margin-bottom: rem(15);
  padding: rem(10);

  > .right {
    .name {
      margin-bottom: rem(6);
      @include font-size(17, true);
      font-weight: bold;
    }
  }
}

.gallery-full {
  .content {
    margin-bottom: rem(20);
  }

  .images {
    .image {
      margin-bottom: rem(15);
      transform: scale(1);
      transition: all 500ms;

      &:hover {
        transition: all 500ms;
        transform: scale(1.05);
      }
    }
  }
}

.gallery-teaser {
  margin-bottom: rem(15);

  .wrapper {
    background-size: cover;
    min-height: rem(230);
    position: relative;
    transform: scale(1);
    transition: all 500ms;

    .content {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      padding: rem(15);
      @include gradient-vertical(transparent, rgba($ui-site-color-2, .7), 0%, 80%);
      transition: all 500ms;

      .title {
        margin: 0;
        color: white;
        font-weight: 300;
      }
    }
  }

  &:hover {
    .wrapper {
      transition: all 500ms;
      transform: scale(1.05);
    }
  }
}

.view-nodes-footer-gallery {
  .content {
    @include susy-clearfix;

    .gallery-teaser,
    > a {
      margin-bottom: rem(6);

      .wrapper {
        min-height: rem(100);

        .content {
          padding: rem(10);

          .title {
            font-weight: 300;
          }
        }
      }
    }

    > a {
      transform: scale(1);
      transition: all 500ms;
      &:hover {
        transition: all 500ms;
        transform: scale(1.05);
      }
    }
  }
}

.paragraph-service-variants-full {
  > .entity {
    box-shadow: 0 0 100px rgba(black, 0.05), inset 0 0 0 1px #CCC;
    margin: rem(10) rem(10) rem(20);
    width: rem(240);
    position: relative;
    padding-bottom: rem(33);

    .title {
      background: $ui-site-color-2;
      padding: rem(10);
      @include font-size(18, true);
      font-weight: bold;
      text-align: center;
      color: white;
    }

    .price {
      text-align: center;
      @include font-size(20, true);
      font-weight: 300;
      padding: rem(10);
      background: #f7f5f5;
      position: relative;
      z-index: -1;
    }

    .content {
      padding: rem(10);
    }

    .order-button {
      @extend .btn;
      @include button-variant(white, $ui-site-color-2, $ui-site-color-2);
      border-radius: 0;
      width: 100%;
      position: absolute;
      bottom: 0;
      font-weight: bold;
    }
  }
}

.service-teaser {
  margin-bottom: rem(15);

  .wrapper {
    background-size: cover;
    min-height: rem(230);
    position: relative;

    .content {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;
      padding: rem(15);
      @include gradient-vertical(transparent, rgba(black, .6), 0%, 100%);

      .title {
        margin: 0;
        color: white;
        font-weight: bold;
        position: absolute;
        bottom: 0;
        left: 0;
        display: block;
        padding: rem(15);
        text-shadow: 0 2px 3px black;
      }
    }
  }
}

#block-block-5 {
  background: url('../../images/front-car.jpg') no-repeat;
  background-size: 100%;
  margin: 0;

  .wrapper {
    padding: rem(50);
    padding-top: rem(40);
    padding-bottom: rem(20);
    position: relative;

    .calculator-button {
      @include button-variant(white, $ui-site-color-1, transparent);
      text-transform: uppercase;
      @include font-size(24, true);
      @include icon(calculator);
      font-weight: bold;
      position: absolute;
      right: rem(50);
    }

    > .title {
      @include font-size(32, true);
      margin: 0 0 rem(20);
      color: white;
      font-weight: 300;
      text-transform: uppercase;

      span {
        font-weight: bold;
      }
    }

    .items {
      .item {
        background: $ui-site-color-2;
        color: white;
        padding: rem(10);
        background-size: 203px;
        display: block;

        &:hover {
          background: lighten($ui-site-color-2, 5%);
        }

        .title {
          @include font-size(24, true);
          font-weight: 300;
          text-transform: uppercase;
          margin: 0 0 rem(15);

          span {
            display: block;
            font-weight: bold;
          }
        }

        .content {
          @include font-size(14, true);
          padding-left: 0;
          padding-bottom: rem(95);
        }

        &:first-child {
          background-image: url('../../images/front-service-1.png');
          background-position: bottom right;
          background-repeat: no-repeat;
          background-position-y: 95%;
        }

        &:nth-child(2) {
          background-image: url('../../images/front-service-2.png');
          background-position: bottom right;
          background-repeat: no-repeat;
          background-position-y: 95%;
        }

        &:nth-child(3) {
          background-image: url('../../images/front-service-3.png');
          background-position: bottom right;
          background-repeat: no-repeat;
          background-position-y: 95%;
        }
      }
    }
  }

  @include breakpoint($xs-media) {
    .wrapper {
      padding: rem(10);
      padding-top: rem(12);

      .items {
        .item {
          margin-bottom: rem(10);
        }
      }

      .calculator-button {
        display: none;
      }
    }
  }

  @include breakpoint($md-media) {
    .wrapper {
      padding-top: rem(160);

      .items {
        .item {
          background-size: auto;

          .content {
            padding-left: rem(110);
            padding-bottom: rem(130);
          }
        }
      }
    }
  }
}

#block-block-6 {
  background: url('../../images/striped-bg.png') repeat;
  margin: 0;
  padding: rem(20) rem(50);

  > .title {
    @include font-size(32, true);
    margin: 0 0 rem(20);
    color: $ui-site-color-1;
    font-weight: 300;
    text-transform: uppercase;

    span {
      font-weight: bold;
    }
  }

  .items {
    .item {
      background: white;
      margin-bottom: rem(10);
      padding: rem(10);

      .title {
        color: black;
        text-align: right;
      }

      .price {
        color: $ui-site-color-1;
        font-weight: bold;
        @include font-size(24, true);
        margin-top: rem(15);
        text-align: right;
      }

      &:first-child {
        background-image: url('../../images/price-image-1.png');
        background-repeat: no-repeat;
        background-position: bottom left;
        background-size: 172px;
      }

      &:nth-child(2) {
        background-image: url('../../images/price-image-2.png');
        background-repeat: no-repeat;
        background-position: bottom left;
        background-size: 135px;
      }

      &:nth-child(3) {
        background-image: url('../../images/price-image-3.png');
        background-repeat: no-repeat;
        background-position: bottom left;
        background-size: 172px;
      }

      &:nth-child(4) {
        background-image: url('../../images/price-image-4.png');
        background-repeat: no-repeat;
        background-position: bottom left;
        background-size: 172px;
      }

      &:nth-child(5) {
        background-image: url('../../images/price-image-5.png');
        background-repeat: no-repeat;
        background-position: bottom left;
        background-size: 172px;
      }

      &:nth-child(6) {
        background-image: url('../../images/price-image-6.png');
        background-repeat: no-repeat;
        background-position: bottom left;
        background-size: 172px;
      }
    }
  }

  @include breakpoint($xs-media) {
    padding: rem(20) rem(10);
  }

  @include breakpoint($lg-media) {
    .items {
      .item {
        .price {
          @include font-size(34, true);
        }
      }
    }
  }
}

#block-block-7 {
  margin: 0;
  padding: rem(20) rem(50);

  > .title {
    @include font-size(32, true);
    margin: 0 0 rem(20);
    color: $ui-site-color-2;
    font-weight: 300;
    text-transform: uppercase;

    span {
      font-weight: bold;
    }
  }

  .items {
    .item {
      color: $ui-site-color-2;
      text-align: center;
      @include font-size(18, true);
      margin-bottom: rem(10);

      > img {
        display: table;
        margin: 0 auto rem(15);
        height: rem(85);
      }
    }
  }

  @include breakpoint($xs-media) {
    padding: rem(20) rem(10);

    > .title {
      @include font-size(28, true);
    }
  }
}

#block-block-8 {
  background: url('../../images/striped-bg.png') repeat;
  margin: 0;
  padding: rem(20) rem(50);

  > .title {
    @include font-size(32, true);
    margin: 0 0 rem(20);
    color: $ui-site-color-2;
    font-weight: 300;
    text-transform: uppercase;

    span {
      font-weight: bold;
    }
  }

  .items {
    .item {
      background: $ui-site-color-2;
      padding: rem(10);
      color: white;
      text-align: center;
      @include font-size(17, true);
      font-weight: bold;
      display: block;

      > img {
        display: table;
        margin: 0 auto rem(15);
      }

      &:not(:last-child) {
        margin-right: rem(15);
      }

      &:hover {
        background: lighten($ui-site-color-2, 5%);
      }
    }
  }

  @include breakpoint($xs-media) {
    padding: rem(20) rem(10);

    > .title {
      @include font-size(28, true);
    }

    .items {
      .item {
        width: 100%;
        margin: 0 0 rem(10) !important;

        &:not(:last-child) {
          margin-right: rem(0);
        }
      }
    }
  }
}

#block-block-9 {
  margin: 0;
  padding: rem(20) rem(50);

  > .title {
    @include font-size(32, true);
    margin: 0 0 rem(20);
    color: $ui-site-color-2;
    font-weight: 300;
    text-transform: uppercase;

    span {
      font-weight: bold;
    }
  }

  .wrapper {
    > .right {
      column-count: 2;
    }
  }

  @include breakpoint($xs-media) {
    padding: rem(20) rem(10);

    > .title {
      @include font-size(28, true);
    }

    img {
      margin-bottom: rem(10);
    }

    .wrapper {
      > .right {
        column-count: 1;
      }
    }
  }
}

#price-calculator {
  .car-type {
    margin-bottom: rem(20);

    .types {
      .type {
        @include font-size(17, true);
        cursor: pointer;
        width: rem(255);
        margin: rem(10);
        border: 1px solid #d0d0d0;

        > input {
          display: none;

          &:checked + label {
            span {
              font-weight: bold;
              color: $ui-site-color-2;
            }
          }
        }

        label {
          display: block;
          font-weight: 300;
          cursor: pointer;
          margin-bottom: 0;

          img {
            height: rem(95);
            display: table;
            margin: 0 auto;
          }

          span {
            display: block;
            text-align: center;
            background: #f3f3f3;
            padding: 6px;
          }
        }
      }
    }
  }

  .additional {
    border: 1px solid #d0d0d0;
    background: #f3f3f3;
    padding: rem(10);
    @include font-size(17, true);
    margin-bottom: rem(20);
  }

  .result {
    background: $ui-site-color-2;
    border: 1px solid darken($ui-site-color-2, 5%);
    color: white;
    text-align: center;
    @include font-size(16, true);
    padding: 10px;
    margin-bottom: rem(20);

    .price {
      @include font-size(30, true);
      font-weight: bold;
    }
  }

  .info {
    color: grey;
    font-style: italic;
  }

  input[name="distance_km"] {
    display: inline-block;

    &:read-only {
      opacity: .25;
      cursor: not-allowed;
    }
  }
}

#block-uvezu-calculator {
  .calculator {
    @include button-variant(white, $ui-site-color-1, transparent);
    text-transform: uppercase;
    @include font-size(22, true);
    @include icon(calculator);
    font-weight: bold;
    width: 100%;
  }

  @include breakpoint($lg-media) {
    @include font-size(24, true);
  }
}

#block-uvezu-testimonial-add {
  .email-textfield {
    display: none;
  }
}

.header-mobile {
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  display: none;
  justify-content: flex-end;
  width: 100%;
  height: 60px;
  background-color: white;
  box-shadow: 0 1px 6px 0 hsla(225, 5.9%, 13.3%, 0.28);

  @include breakpoint($xs-media) {
    display: flex;
  }
}

.header-mobile__site-name {
  font-size: 18px;
  font-weight: bold;
  line-height: 60px;
  padding: 0 16px;
  color: black;
  margin-right: auto;
}

.header-mobile__phone {
  width: 60px;
  text-align: center;
  color: black;

  &:before {
    @extend .fa;
    @extend .fa-phone;
    font-size: 24px;
    line-height: 60px;
  }
}

.header-mobile__toggle {
  width: 60px;
  text-align: center;

  &:before {
    @extend .fa;
    @extend .fa-bars;
    font-size: 24px;
    line-height: 60px;
  }
}

.header-mobile__toggle--active {
  &:before {
    content: ""
  }
}

.mobile-navigation {
  position: fixed;
  z-index: 100;
  top: 60px;
  left: 0;
  display: none;
  width: 100%;
  height: calc(100% - 60px);
  background-color: #ffffff;
}

.mobile-navigation--active {
  @include breakpoint($xs-media) {
    display: flex;
    flex-direction: column;
  }
}

.mobile-menu {
  width: 100%;
  margin: 24px 0;
  padding: unset;
  list-style: none;
  border-top: 1px solid #eeeeee;
}

.mobile-menu__item {
  border-bottom: 1px solid #eeeeee;
}

.mobile-menu__link {
  font-size: 18px;
  display: block;
  padding: 8px 16px;
  color: black;
}

.mobile-menu__submenu {
  width: 100%;
  margin: 0 0 0 24px;
  padding: unset;
  list-style: none;
}

.mobile-menu__submenu-link {
  font-size: 16px;
  display: block;
  padding: 8px 16px;
  color: black;
}

.mobile-24hours {
  display: grid;
  grid-template-columns: 80px 1fr;
  grid-gap: 24px;
  padding: 24px;
  align-items: center;
}

.mobile-24hours__title {
  color: $ui-site-color-1;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 20px;
  margin-bottom: 8px;
}

.mobile-24hours__price {
  font-weight: bold;
  color: $ui-site-color-2;
}