/**
 * @file
 *
 * XS media query styles.
 */

@include breakpoint($xs-media) {
  // Your styles here.

  .paragraph-gallery-full {
    .gallery {
      @include susy-clearfix;

      .item {
        @include gallery(6);
      }
    }
  }
}
