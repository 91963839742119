/**
 * @file
 * Define common padding and border radius sizes and more. Values based on 14px
 * text and 1.428 line-height (~20px to start).
 */

// Paddings.
$padding-base-vertical:   6px !default;
$padding-base-horizontal: 12px !default;
$padding-large-vertical:   10px !default;
$padding-large-horizontal: 16px !default;
$padding-small-vertical:   5px !default;
$padding-small-horizontal: 10px !default;
$padding-xs-vertical:   1px !default;
$padding-xs-horizontal: 5px !default;
$icon-padding: 6px !default;

// Border radius.
$border-radius-small: 3px !default;
$border-radius-base:  4px !default;
$border-radius-large: 6px !default;

// FontAwesome - rewrite to real path of font folder.
$fa-font-path: '../scss/component/font-awesome/fonts';