/**
 * @file
 * Layout settings.
 */

// Susy settings.
// Check out more info: http://susy.readthedocs.org/en/latest/settings/
$susy: (
  output: float,
  math: fluid,
  container: auto,
  container-position: center,
  last-flow: to,
  columns: 12,
  gutters: .25,
  gutter-position: after,
  global-box-sizing: border-box,
);

/**
 * Some blocks classes.
 */
.block {
  margin-bottom: 10px;
}
.inline-block {
  margin-right: 10px;
  display: inline-block;
  vertical-align: middle;
}
.inline-block-tight {
  @extend .inline-block;
  margin-right: 5px;
}
