/**
 * @file
 */

$form-item-padding: 10px !default;

$input-height-base:  $line-height-computed + ($padding-base-vertical * 2) + 2 !default;
$input-height-large: ceil($font-size-large * $line-height-large) + ($padding-large-vertical * 2) + 2 !default;
$input-height-small: floor($font-size-small * $line-height-small) + ($padding-small-vertical * 2) + 2 !default;
// Input default state.
$input-bg:            white;
$input-border-color:  #c3c3c3;
$input-box-shadow:    inset 0 1px 3px rgba(black, .1);
$input-border-radius: 0;
// input:focus
$input-bg-focus:           white;
$input-border-color-focus: darken($ui-site-color-2, 15%);
$input-box-shadow-focus:   $input-box-shadow, 0 0px 0px 3px rgba($ui-site-color-2, .25);
// input:disabled
$input-bg-disabled:     rgb(250, 250, 250);
$input-color-disabled:  rgba(163, 163, 163, 1);

// Select default state.
$select-box-shadow:   none;
$select-border-color: $input-border-color;
// select:focus
$select-border-color-focus: $input-border-color-focus;
$select-box-shadow-focus:   $input-box-shadow-focus;
