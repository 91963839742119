/**
 * @file
 * Define the breakpoints at which your layout will change, adapting to different screen sizes.
 */

$site-width: 1154px !default;
// Media sizes.
$xs-media: max-width 767px;
$sm-media: 768px;
$md-media: 992px;
$lg-media: min-width 1200px;
