.breadcrumb {
  color: $breadcrumb-color;
  background: $breadcrumb-bg;
  padding: $breadcrumb-padding-vertical $breadcrumb-padding-horizontal;
  margin-bottom: $breadcrumb-margin-bottom;
  border-radius: $border-radius-base;
  font-size: $breadcrumb-font-size;
  .delimiter {
    color: $breadcrumb-delimiter-color;
  }
}
