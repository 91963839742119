/**
 * @file
 * Styles for breadcrumbs.
 */

$breadcrumb-padding-vertical:   0 !default;
$breadcrumb-padding-horizontal: 0 !default;
$breadcrumb-margin-bottom:      10px !default;
$breadcrumb-bg:                 none !default;
$breadcrumb-color:              #777 !default;
$breadcrumb-delimiter-color:    #ccc !default;
$breadcrumb-font-size:          $font-size-base;
