/**
 * @file
 *
 * LG media query styles.
 */

@include breakpoint($lg-media) {
  #app {
    @include container($site-width);
  }

  .view-nodes-articles {
    .content {
      @include susy-clearfix;

      .article-teaser {
        @include gallery(4);
      }
    }
  }
}
