/**
 * @file
 *
 * MD media query styles.
 */

@include breakpoint($md-media) {
  #app {
    @include container(992px);
  }

  #header {
    .top {
      @include susy-clearfix;

      #logo-and-slogan {
        @include span(4);
      }

      #block-block-1 {
        @include span(4);
      }

      #block-block-2 {
        @include span(4 last);
      }
    }
  }

  #content {
    @include span(9);
    .no-sidebars & {
      @include full();
    }

    .sidebar-first & {
      @include span(9 last);
    }
  }
  #sidebar-first {
    @include span(3);
  }
  #sidebar-second {
    @include span(3 last);
  }

  #block-block-6 {
    .items {
      @include susy-clearfix;

      .item {
        @include gallery(4);
      }
    }
  }

  #block-block-7 {
    .items {
      @include susy-clearfix;

      .item {
        @include gallery(3);
      }
    }
  }
}
