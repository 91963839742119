/**
 * @file
 *
 * SM media query styles.
 */

@include breakpoint($sm-media) {
  #app {
    @include container(768px);
  }

  #header {
    .top {
      @include susy-clearfix;

      #logo-and-slogan {
        @include span(5);
      }

      #block-block-1 {
        @include span(4);
      }

      #block-block-2 {
        @include span(3 last);
      }
    }
  }

  #block-block-2 {
    .image-and-text {
      @include susy-clearfix;

      .image-wrapper {
        @include span(5);
      }

      .text {
        @include span(7 last);
      }
    }
  }

  #superfish-1 {
    @include flexbox;
    @include flex-flow();
    @include justify-content();
    > li {
      @include flex(1, 1, auto);
    }
  }

  #footer {
    .content {
      @include susy-clearfix;

      .block {
        @include gallery(4);
      }
    }
  }

  #contact-entityform-edit-form {
    .pre-instructions {
      .first {
        @include susy-clearfix;
        .photo {
          float: right;
        }
      }
      .second {
        @include susy-clearfix;
        .scheme {
          @include span(8);
        }
        .route {
          @include span(4 last);
        }
      }
      .third {
        @include susy-clearfix;
        .ppl {
          @include span(8);
          .photo {
            float: right;
          }
        }
        .form-pane {
          @include span(4 last);
        }
      }
    }
  }

  .view-nodes-articles {
    .content {
      @include susy-clearfix;

      .article-teaser {
        @include gallery(6);
      }
    }
  }

  .article-teaser {
    @include susy-clearfix;

    > .left {
      @include span(4);
    }

    > .right {
      @include span(8 last);
    }
  }

  .paragraph-gallery-full {
    .gallery {
      @include susy-clearfix;

      .item {
        @include gallery(3);
      }
    }
  }

  .testimonial-teaser {
    @include susy-clearfix;

    > .left {
      @include span(2);
    }

    > .right {
      @include span(10 last);
    }
  }

  .gallery-full {
    .images {
      @include susy-clearfix;

      .image {
        @include gallery(3);
      }
    }
  }

  .view-nodes-gallery {
    .content {
      @include susy-clearfix;

      .gallery-teaser {
        @include gallery(6);
      }
    }
  }

  .view-nodes-footer-gallery {
    .content {
      @include susy-clearfix;

      .gallery-teaser,
      > a {
        @include gallery(6);
      }
    }
  }

  .paragraph-service-variants-full  {
    @include flexbox;
    @include flex-wrap(space-between);
    @include flex-flow(row wrap);
    @include justify-content(center);
  }

  .view-nodes-services {
    .content {
      @include susy-clearfix;

      .service-teaser {
        @include gallery(6);
      }
    }
  }

  #block-block-5 {
    .items {
      @include susy-clearfix;

      .item {
        @include gallery(4);
      }
    }
  }

  #block-block-6 {
    .items {
      @include susy-clearfix;

      .item {
        @include gallery(6);
      }
    }
  }

  #block-block-7 {
    .items {
      @include susy-clearfix;

      .item {
        @include gallery(6);
      }
    }
  }

  #block-block-8 {
    .items {
      @include flexbox;
      @include flex-flow();
      @include justify-content();

      .item {
        @include flex(1, 1, auto);
      }
    }
  }

  #block-block-9 {
    .wrapper {
      @include susy-clearfix;

      .left {
        @include span(4);
      }

      .right {
        @include span(8 last);
      }
    }
  }

  #price-calculator {
    .car-type {
      .types {
        @include flexbox;
        @include flex-wrap(space-between);
        @include flex-flow(row wrap);
        @include justify-content(center);
        .type {
        }
      }
    }
  }
}
