/**
 * @file
 * Buttons background, borders and colors.
 */

$btn-font-weight: normal !default;

$btn-default-color:  #333 !default;
$btn-default-bg:     #fff !default;
$btn-default-border: transparent !default;
$btn-border-radius:  0;

$btn-primary-color:  #fff !default;
$btn-primary-bg:     $brand-primary !default;
$btn-primary-border: darken($btn-primary-bg, 5%) !default;

$btn-success-color:  #fff !default;
$btn-success-bg:     $brand-success !default;
$btn-success-border: darken($btn-success-bg, 5%) !default;

$btn-warning-color:  #fff !default;
$btn-warning-bg:     $brand-warning !default;
$btn-warning-border: darken($btn-warning-bg, 5%) !default;

$btn-danger-color:  #fff !default;
$btn-danger-bg:     $brand-error !default;
$btn-danger-border: darken($btn-danger-bg, 5%) !default;

$btn-info-color:  #fff !default;
$btn-info-bg:     $brand-info !default;
$btn-info-border: darken($btn-info-bg, 5%) !default;
