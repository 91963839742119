/**
 * @file
 * In this file stored global variables. You can adjast values on your needs.
 * This exercises all UI components and acts as styleguide.
 * They separated to easly configuration and controlling.
 */

@import "variables/colors";
@import "variables/typography";
@import "variables/components";
@import "variables/tables";
@import "variables/buttons";
@import "variables/forms";
@import "variables/media";
@import "variables/messages";
@import "variables/breadcrumbs";
@import "variables/contextual";
@import "variables/tabs";
